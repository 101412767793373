.loading_modal{
    position: absolute;
    z-index: 10000;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    .loading_cover{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        position: relative;
        img{
            position: absolute;
            top:50%;
            left: 50%;
            margin-left: -90px;
            margin-top: -90px;
        }
        .loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            font-size: 32px;
        }

        .loader div {
            position: absolute;
            top: 50%;
            left: 50%;
            will-change: transform;
        }

        .loader div span {
            display: block;
            width: 5px;
            height: 15px;
            background: #fff;
            border-radius: 0.08em;
        }

        .loader .item-1 {
            margin-left: 11.313708496px;
            margin-top: -11.313708496px;
            -webkit-animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        }

        .loader .item-1 span {
            -webkit-transform: rotate(45deg) translateZ(0);
            transform: rotate(45deg) translateZ(0);
        }

        .loader .item-2 {
            margin-left: 0px;
            margin-top: -16px;
            -webkit-animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        }

        .loader .item-2 span {
            -webkit-transform: rotate(180deg) translateZ(0);
            transform: rotate(180deg) translateZ(0);
        }

        .loader .item-3 {
            margin-left: -11.313708496px;
            margin-top: -11.313708496px;
            -webkit-animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        }

        .loader .item-3 span {
            -webkit-transform: rotate(135deg) translateZ(0);
            transform: rotate(135deg) translateZ(0);
        }

        .loader .item-4 {
            margin-left: -16px;
            margin-top: 0px;
            -webkit-animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        }

        .loader .item-4 span {
            -webkit-transform: rotate(270deg) translateZ(0);
            transform: rotate(270deg) translateZ(0);
        }

        .loader .item-5 {
            margin-left: -11.313708496px;
            margin-top: 11.313708496px;
            -webkit-animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        }

        .loader .item-5 span {
            -webkit-transform: rotate(225deg) translateZ(0);
            transform: rotate(225deg) translateZ(0);
        }

        .loader .item-6 {
            margin-left: 0px;
            margin-top: 16px;
            -webkit-animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        }

        .loader .item-6 span {
            -webkit-transform: rotate(360deg) translateZ(0);
            transform: rotate(360deg) translateZ(0);
        }

        .loader .item-7 {
            margin-left: 11.313708496px;
            margin-top: 11.313708496px;
            -webkit-animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        }

        .loader .item-7 span {
            -webkit-transform: rotate(315deg) translateZ(0);
            transform: rotate(315deg) translateZ(0);
        }

        .loader .item-8 {
            margin-left: 16px;
            margin-top: 0px;
            -webkit-animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: spin_full 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        }

        .loader .item-8 span {
            -webkit-transform: rotate(450deg) translateZ(0);
            transform: rotate(450deg) translateZ(0);
        }

        @-webkit-keyframes spin_full {
            0% {
                -webkit-transform: rotate(0deg) translateZ(0);
                transform: rotate(0deg) translateZ(0);
            }

            100% {
                -webkit-transform: rotate(360deg) translateZ(0);
                transform: rotate(360deg) translateZ(0);
            }
        }

        @keyframes spin_full {
            0% {
                -webkit-transform: rotate(0deg) translateZ(0);
                transform: rotate(0deg) translateZ(0);
            }

            100% {
                -webkit-transform: rotate(360deg) translateZ(0);
                transform: rotate(360deg) translateZ(0);
            }
        }
        .loading_text{
            font-size: 14px;
            color: #fff;
            transform: translate(-50%, 50px);
            width: 80%;
            font-weight: bold;
            text-align: center;
        }
    }
}