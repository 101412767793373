@import '~antd/dist/antd.css';

.msg_reason{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}


.product_title{
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  overflow: hidden;
}