.login_box{
    width: 400px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    padding: 30px 20px 0 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    border-radius: 10px;
}