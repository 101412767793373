.content_tabs_box{
    .tool_bar{
        margin-bottom: 10px;
    }
    .label{
        display: inline-block;
        min-width: 70px;
        vertical-align: middle;
    }
    .inline_el{
        display: inline-block;
        width: 120px;
        margin-right: 20px;
    }

    .inline_el_min{
        display: inline-block;
        width: 80px;
        margin-right: 20px;
    }
    .add_margin{
        margin-right: 30px;
    }
    .pro_table{
        margin-bottom: 20px;
    }
    .editor-wrapper{
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
    }
}

.product_title{
    text-overflow: ellipsis;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 40px;
    display: inline-block;
}