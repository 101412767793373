.logo{
    height: 65px;
    background: #ddd;
    padding: 2px;
    box-sizing: border-box;
}
.header_box{
    .menu_btn{
        border:0px;
        &:focus{
            color: rgba(0, 0, 0, 0.65);
        }
    }
    .user_btn{
        float: right;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        a{
            text-decoration: none;
        }
    }
    .user_img{
        width: 24px;
        height: 24px;
        border-radius:50%;    
        vertical-align: middle;
        margin-right: 8px;
    }
    .user_name_text{
        color: #fff;
        cursor: pointer;
    }
}
.tabs_content{
    padding: 20px;
    box-sizing: border-box;
}
.ant-tabs-bar{
    margin: 0px;
}
.ant-tabs-nav{
    margin-bottom: 0 !important;
}
.tabs_content{
    .ant-tabs-content{
        background: #fff;
        height: calc(100vh - 150px);
        border-left: 1px solid #f0f2f5;
        border-right: 1px solid #f0f2f5;
        border-bottom: 1px solid #f0f2f5;
        box-sizing: border-box;
        padding: 20px;
        position: relative;
    }
}
.ant-tabs-tab{
    background: #e2e2e2 !important;
}
.ant-tabs-tab-active{
    background: #fff !important;
}
.content_tabs_box{
    height: 100%;
}
.content_tabs_box{
    .ant-tabs-tab{
        background: transparent !important;
        padding: 8px !important;
    }
}
